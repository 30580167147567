
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
@Component({
  name: "FeedbackDetail",
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class FeedbackDetail extends Vue {
  public visible: boolean = false;
  public loading: boolean = false;
  public detail: any = {};
  public imgList: string[] = [];
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()

  show(value: any): void {
    this.visible = true;
    this.loading = true;
    this.$apis.feedbackQueryById({ id: value.id }).then((res: any) => {
      if (res.code === "200") {
        this.detail = res.data;
        this.imgList = JSON.parse(res.data.imageUrl);
        this.loading = false;
      }
    });
  }

  //创建前钩子函数
  created(): void {}
}
