
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import FeedbackDetail from "./components/FeedbackDetail.vue";
import { namespace } from "vuex-class";
const CommonModule = namespace("common");

@Component({
  name: "Feedback",
  components: { FeedbackDetail },
  filters: {
    //局部过滤器
  },
})
export default class Feedback extends Vue {
  @CommonModule.State("triggerFeedbackQuery") trigger!: number;

  // 表头
  public columns: any[] = [
    {
      title: "反馈类型",
      dataIndex: "type",
      ellipsis: true,
      align: "center",
    },
    {
      title: "问题描述",
      dataIndex: "description",
      ellipsis: true,
    },
    {
      title: "联系方式",
      dataIndex: "contact",
      ellipsis: true,
      align: "center",
    },
    {
      title: "提交时间",
      dataIndex: "createTime",
      align: "center",
      width: "180px",
      scopedSlots: { customRender: "createTime" },
    },
    {
      title: "状态",
      dataIndex: "process",
      scopedSlots: { customRender: "process" },
      width: "60px",
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "",
      width: "100px",
      scopedSlots: { customRender: "action" },
      align: "center",
    },
  ];
  // 表格数据
  public tableList: any[] = [];
  // 分页
  public pagination: any = {
    total: 0,
    pageNum: 1,
    pageSize: 10,
    showTotal: (total: number, range: number[]) =>
      `${range[0]}-${range[1]} 共 ${total} 条`,
    showQuickJumper: true,
  };
  // 加载
  public loading: boolean = false;
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  @Watch('trigger')
  triggerChange() {
    this.queryList()
  }
  //ref 引用元素 Ref('ref')
  @Ref("FeedbackDetail")
  FeedbackDetailRef!: any;

  queryList(): void {
    this.$apis
      .feedbackPageList({
        isPage: 1,
        pageNum: this.pagination.pageNum,
        pageSize: this.pagination.pageSize,
      })
      .then((res: any) => {
        if (res.code === "200") {
          this.tableList = res.data.list;
          this.pagination.total = res.data.total;
        }
      });
  }

  handleDetail(record: any): void {
    this.FeedbackDetailRef.show(record);
  }

  //创建前钩子函数
  created(): void {
    this.queryList();
  }
}
